import React, { useState } from "react";

const ChatInput = ({ onAsk, chatHistory, setChatHistory }) => {
  const [prompt, setPrompt] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const currentPrompt = prompt;
    setPrompt("");
    setChatHistory([...chatHistory, { role: "user", content: prompt }]);
    onAsk(currentPrompt);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="prompt" className="block mb-2 text-white">
        Prompt:
      </label>
      <input
        id="prompt"
        className="w-full border border-gray-300 p-2 rounded mb-4"
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded"
        type="submit"
      >
        Ask LadGPT
      </button>
    </form>
  );
};

export default ChatInput;
