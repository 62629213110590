import React, { useState } from "react";
import { API } from "aws-amplify";
import Chats from "./components/Chats";
import ChatInput from "./components/ChatInput";
import "./styles/Chats.css";
import dyer from "./assets/images/dyer.jpeg";

function App() {
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAsk = async (prompt) => {
    setLoading(true);

    try {
      const data = await API.post("ladgptapi", "/chat", {
        body: { prompt, chatHistory },
      });

      setChatHistory([
        ...chatHistory,
        { role: "user", content: prompt },
        { role: "assistant", content: data.answer },
      ]);
      setErrorMessage(""); // Clear any previous error messages
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrorMessage(
          "We've fucked it son, we've spent all our ChatGPT tokens for the day. Try again tomorrow, yeah?"
        );
      } else {
        alert("Error: Could not fetch answer");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-black">LadGPT</h1>
        <h2 className="text-xl mb-4 text-black">Ask Danny anything</h2>
        <img src={dyer} alt="propa nawty" className="mb-4"></img>
        <ChatInput
          onAsk={handleAsk}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
        />
        <div className="mt-6">
          {errorMessage && (
            <p className="text-red-500 mb-4 lad-gpt-text">{errorMessage}</p>
          )}
          <h2 className="text-xl font-bold mb-2 text-white">Chat History:</h2>
          <Chats chatHistory={chatHistory} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default App;
